<template>
    <div>
        <!-- <Chart /> -->

        <div class="name">
            渗漏监测
        </div>

        <img src="./chart.png" style="width:100%" alt="">

        <div class="cards">
            <div class="card">
                <div class="header">
                    <div class="title">采集详情</div>
                </div>
                <div class="table">
                    <div class="row">
                        <div class="col">采集指标</div>
                        <div class="col">数据采集时间</div>
                        <div class="col">温度</div>
                    </div>
                    <div class="row" v-for="item in 4" :key="item">
                        <div class="col">温度</div>
                        <div class="col">2023-12-12 12:32:23</div>
                        <div class="col">12.1</div>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="header">
                    <div class="title">采集详情</div>
                </div>
                <div class="table">
                    <div class="row">
                        <div class="col">采集指标</div>
                        <div class="col">数据采集时间</div>
                        <div class="col">温度</div>
                    </div>
                    <div class="row" v-for="item in 4" :key="item">
                        <div class="col">温度</div>
                        <div class="col">2023-12-12 12:32:23</div>
                        <div class="col">12.1</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {

}
</script>

<style lang="less" scoped>
.cards {
    display: flex;
    gap: 2em;

    .card {
        .header {

            .title {
                font-weight: bold;
            }
        }

    }
}

.table {
    .row {
        display: flex;

        .col {
            flex: 1;
        }
    }
}
</style>